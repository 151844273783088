import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';

import {
  faChevronLeft,
  faChevronRight,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons';

import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import { getLembretes } from '../../../services/api';
import { ResponseAPILembrete } from '../../../types';
import { TIME_INTERVAL } from './PostIt.constants';
import getRandomColor from './PostIt.helpers';

const NavigatorContainer = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: 40,
  borderBottomLeftRadius: 15,
  userSelect: 'none',
  display: 'none', // flex on hover
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
});

const PostNavigator = styled('div')(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: '100%',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none',
  ':hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function PostIt(props: BoxProps): JSX.Element {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const lembreteOpen = true;

  const { data: lembretes = [] } = useQuery(['lembretes'], async () => {
    const { data } = await getLembretes<ResponseAPILembrete>();
    return data.lembretes;
  });

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    const { length } = lembretes;

    const timer = setInterval(() => {
      setCurrentIndex(currentIndex < length - 1 ? currentIndex + 1 : 0);
    }, TIME_INTERVAL);

    return () => clearInterval(timer);
  }, [currentIndex, lembretes]);

  const handleNavigateToPrevious = () => {
    setCurrentIndex(previousIndex =>
      previousIndex > 0 ? currentIndex - 1 : lembretes.length - 1,
    );
  };

  const handleNavigateToNext = () => {
    setCurrentIndex(previousIndex =>
      previousIndex < lembretes.length - 1 ? currentIndex + 1 : 0,
    );
  };

  return (
    <Box>
      <Typography
        color={grey[600]}
        fontWeight="bold"
        paddingLeft="10px"
        paddingTop="10px"
        mb={0.5}
      >
        Lembre-se!
      </Typography>

      <Box
        {...props}
        sx={{
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          borderBottomLeftRadius: 15,
          background: getRandomColor(),
          // background: '#7fc75b',
          minHeight: 170,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          ':hover .postit__navigator': {
            display: 'flex',
          },
        }}
      >
        {lembreteOpen && (
          <NavigatorContainer className="postit__navigator">
            <PostNavigator onClick={handleNavigateToPrevious}>
              <FontAwesomeIcon icon={faChevronLeft} size="sm" color="white" />
            </PostNavigator>

            <PostNavigator onClick={handleNavigateToNext}>
              <FontAwesomeIcon icon={faChevronRight} size="sm" color="white" />
            </PostNavigator>
          </NavigatorContainer>
        )}

        <Box
          sx={{
            position: 'absolute',
            top: -4,
            right: -1,
          }}
        >
          <FontAwesomeIcon
            icon={faThumbtack}
            size="2x"
            color="rgba(30, 30, 30, .55)"
            transform={{ rotate: 30 }}
          />
        </Box>

        <Box
          sx={{
            justifyContent: 'center',
            display: 'flex',
            flex: 'auto',
            p: 2,
          }}
        >
          {lembreteOpen ? (
            <Typography
              textAlign="center"
              fontWeight="bold"
              color="white"
              fontSize="1.2rem"
              maxWidth="252px"
              sx={{
                '& a': {
                  color: '#005ba8',
                  textDecoration: 'none',
                  '&:hover': {
                    color: '#0070cb',
                  },
                },
              }}
              dangerouslySetInnerHTML={{
                __html:
                  lembretes[currentIndex]?.text ||
                  '<p>Nenhum lembrete para exibir</p>',
              }}
            />
          ) : (
            <Typography
              textAlign="center"
              fontWeight="bold"
              color="white"
              fontSize="1.2rem"
              maxWidth="252px"
              onClick={handleDialogOpen}
              sx={{
                mt: 3,
                cursor: 'pointer',
              }}
            >
              {
                '8ª Sessão Ordinária do Conselho Superior da Defensoria Pública, Click aqui para assistir.'
              }
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: -4,
            right: -1,
          }}
        >
          <FontAwesomeIcon
            icon={faThumbtack}
            size="2x"
            color="rgba(30, 30, 30, .55)"
            transform={{ rotate: 30 }}
          />
        </Box>

        {/* dialogOpen, handleDialogClose, urlEmail */}
        <Dialog
          fullWidth
          maxWidth={false}
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{
            mx: { xl: 40, lg: 30, md: 20, sm: 10, sx: 0 },
            mt: 10,
          }}
        >
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              endIcon={<FontAwesomeIcon icon="xmark" />}
              sx={{ my: 0, mr: 2 }}
            />
          </DialogActions>
          <DialogContent dividers={false}>
            <iframe
              id="iframe_youtube"
              width="100%"
              height={window.screen.height * 0.6}
              src="https://www.youtube.com/embed/7sjxXlhaFK4?si=xFw7YfeNvOivcILu?controls=0&autoplay=1"
              style={{ pointerEvents: 'none' }}
              allow={'autoplay;'}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}
